.card {
  width: 230px;
  height: 175px;
  border-radius: 15px;
  background: rgba(105, 13, 197, 0.103);
  backdrop-filter: blur(50px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  justify-content: center;
  transition: transform 0.3s ease;
}

.card::before {
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: -30%;
  left: -15%;
  border-radius: 50%;
  border: 35px solid rgba(255, 255, 255, 0.102);
  transition: all .8s ease;
  filter: blur(.5rem);
}

.card:hover::before {
  width: 140px;
  height: 140px;
  top: -40%;
  left: 60%;
  filter: blur(0rem);
}

.card:hover {
  transform: scale(1.05);
}

.text {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  gap: 10px;
  font-weight: 900;
  font-size: 1.2em;
}

.subtitle {
  font-size: 0.95em;
  font-weight: 300;
  color: rgba(240, 248, 255, 0.691);
}

.custom-btn {
  background: linear-gradient(267.39deg, #FB54AE 0.32%, #2F64FF 100.25%);
  color: white;
  border-radius: 50px;
  transition: background 0.3s ease; /* Smooth transition effect */
}

.custom-btn:hover {
  background: linear-gradient(267.39deg, #2F64FF 0.32%, #FB54AE 100.25%) !important;
}

.cardPattern {
  border-radius: 15px;
  background: rgba(105, 13, 197, 0.103);
  backdrop-filter: blur(50px);
  transition: transform 0.3s ease;
}

.button {
  position: relative;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(45deg, #0ce39a, #69007f, #fc0987);
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
}
 
.button span {
  position: relative;
  z-index: 1;
}
 
.button::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: #200627;
  border-radius: 50px;
  transition: 0.5s;
}
 
.button:hover::before {
  opacity: 0.7;
}
 
.button::after {
  content: "";
  position: absolute;
  inset: 0px;
  background: linear-gradient(45deg, #0ce39a, #69007f, #fc0987);
  border-radius: 9px;
  transition: 0.5s;
  opacity: 0;
  filter: blur(20px);
}
 
.button:hover:after {
  opacity: 1;
}

.border-button {
  border: 1.5px solid transparent;
  border-radius: 50px !important;
  box-sizing: border-box;
}

.border-button:hover {
  color: transparent;
  background: linear-gradient(267.39deg, #FF60B6 0.32%, #2960FD 100.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border: 1.5px solid #FF60B6;
  border-radius: 50px !important;
}

.CreateJDFormBlock {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.CreateJDForm {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  max-height: 80%;
}

.JDSection1,
.JDSection2 {
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.CreateJDBtns {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.JDApp {
  max-width: 1200px;
  margin: 30px auto;
  padding: 25px;
  background-color: #ffffff; /* Changed to white */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #d1d5db;
  border-radius: 12px;
}

.main-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.priority-radio-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-q {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 600px;
  width: 90%;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.generated-jd {
  margin-top: 20px;
  padding: 25px;
  background-color: #f0f8ff; /* Light blue background */
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.generated-jd h3 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.generated-jd ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.generated-jd li {
  margin-bottom: 5px;
}

.ideas-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #1e3a8a; /* Blue spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media(max-width:768px) {
  .JDApp {
    padding: 15px;
  }

  .main-container {
    flex-direction: column;
  }

  .suggested-skills {
    margin-left: 0;
  }

  .JDApp button {
    width: 100%;
  }
}

.non-editable-jd {
  overflow: auto;
  max-height: 600px;
  padding: 20px;
  border: 1px solid #d1d5db;
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #333;
  transition: box-shadow 0.3s ease;
}

.non-editable-jd:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.non-editable-jd h3 {
  margin: 0 0 10px;
  font-size: 1.5em;
  color: #1e3a8a; /* Blue color for headings */
}

.non-editable-jd ul {
  list-style-type: disc;
  padding-left: 20px;
}

.non-editable-jd li {
  margin-bottom: 8px;
}

.interview-config-container {
  max-width: 11000px;
  margin: 15px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 9px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.config-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.config-section label {
  font-weight: 600;
  margin-right: 10px;
}

.input-container {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 20px;
  border: 1px solid #d1d5db;
  border-radius: 12px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease, transform 0.3s;
}

.input-container:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}

.input-container .input-group {
  display: flex;
  flex-direction: column;
}

.input-container input[type="text"],
.input-container input[type="number"],
.input-container textarea,
.input-container select {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-container input:focus,
.input-container textarea:focus,
.input-container select:focus {
  border-color: #1e3a8a;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.4);
}

.input-container .input,
.input-container select {
  width: 60%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="radio"] {
  margin-right: 5px;
}

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.accept-btn {
  color: #27ae60;
}

.delete-btn {
  color: #e74c3c;
}

.analyze-button {
  background-color: #1e3a8a; /* Blue background for analyze button */
  color: #fff;
}

.analyze-button:hover {
  background-color: #2980b9;
}

.submit-button {
  background-color: #27ae60;
  color: #fff;
}

.submit-button:hover {
  background-color: #219150;
}

.generate-button {
  background-color: #e67e22;
  color: #fff;
}

.generate-button:hover {
  background-color: #d35400;
}

.save-button {
  background-color: #2ecc71;
  color: #fff;
}

.save-button:hover {
  background-color: #fff; /* White on hover */
}

.publish-button {
  background-color: #e74c3c;
  color: #fff;
}

.publish-button:hover {
  background-color: #c0392b;
}

.JDApp button {
  padding: 14px 24px;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.close-button {
  background-color: #e74c3c; /* Close button color */
  color: white;
  border-radius: 8px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}


.remove-skill-btn {
  background: none;
  border: none;
  color: red;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}





/* Textarea specific styling */
.JDApp textarea {
  resize: vertical;
}

.JDApp textarea.roles-input {
  resize: vertical;
  min-height: 200px;
}

/* Suggested Skills Heading Styles */
.suggested-skills h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #333;
}

.suggested-skills p {
  font-style: italic;
  color: #888;
}

.skill-JD-badge:hover {
  background-color: #d6e0f2;
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.skill-badge-btn {
  width: fit-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  margin-left: 10px;
}

.skill-badge-btn:hover {
  color: #27ae60;
}

/* Skill Badge Styles */
.skill-badge {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px 15px;
  border-radius: 8px; /* Example value for --border-radius */
  margin: 5px 0;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Example value for --shadow-color */
}

.skill-JD-badge:hover .remove-skill-btn {
  opacity: 1;
}

.skill-JD-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 18px;
  margin: 5px;
  justify-content: space-between;
  background-color: #e0e7ff;
  color: #1e3a8a;
  border-radius: 16px;
  border: 1px solid #c0c4cc;
  font-weight: 500;
  position: relative;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.topic-badge {
  background-color: #ffe0b2;
  padding: 8px 12px;
  border-radius: 5px;
  margin: 5px 0;
}

.suggested-skills {
  flex: 1;
  background-color: #e8f5e9;
  padding: 20px;
  border-radius: 8px; /* Example value for --border-radius */
  border: 1px solid #c8e6c9;
  margin-left: 20px;
  max-height: 570px;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Example value for --shadow-color */
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.suggested-skills h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #2e7d32;
  text-align: center;
  font-weight: bold;
}

.skills-container {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.skills-container h3 {
  margin: 10px 0;
  color: #2c3e50;
}

.skillsJD-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

h1{
font-family: Poppins;
font-size: 30px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0.03em;
text-align: center;
}

.hr-profiles .table-container {
  width: 100%;
}

.hr-profiles .table-header th{
  white-space: nowrap;
  padding: 8px;
  text-align: center;
}

.JDFormBlock .JDSection1,
.JDFormBlock .JDSection2 {
  margin-bottom: 20px;
}

.JDFormBlock label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.JDFormBlock input[type="text"],
.JDFormBlock input[type="number"],
.JDFormBlock select {
  width: 150px;
  padding: 10px;
  margin: 5px 0 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.JDFormBlock input[type="checkbox"] {
  margin-right: 10px;
}

.JDFormBlock .checkbox-container {
  display: flex;
  align-items: center;
}

.JDFormBlock select:focus,
.JDFormBlock input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.JDFormBlock #JDsetsSelect {
  display: inline-block;
  width: 150px;
}

.JDFormBlock pre {
  font-family: 'Poppins', monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 8px;
  max-height: 350px;
  overflow-y: scroll;
  background-color: #fff;
}

.JDFormBlock .CreateJDBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.JDFormBlock button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.JDFormBlock button:hover {
  background-color: #0056b3;
}

.JDFormBlock hr {
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  .JDFormBlock .JDForm {
    width: 95%;
  }

  .JDFormBlock #JDsetsSelect {
    width: 100%;
  }

  .JDFormBlock .CreateJDBtns {
    flex-direction: column;
  }

  .JDFormBlock .CreateJDBtns button {
    margin-bottom: 10px;
    width: 100%;
  }
}

.JDFormBlock .JDSection2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-schedular-modal .react-calendar{
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border-radius: 15px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  padding: 18px !important;
}

.custom-schedular-modal .rsm-date-title{
  font-size: 20px;
}

.custom-schedular-modal button{
  background: linear-gradient(142.42deg, #19b0bb 38.71%, #0952DA 140.16%);
  border-radius: 50px !important;
  margin: 10px;
}
.custom-schedular-modal button:hover{
transform: scale(1.05) !important;
color: black !important;
}

.custom-schedular-modal .react-calendar__tile {
  cursor: not-allowed;
  border: solid 0.5px #c3c3c3 !important;
}

.custom-schedular-modal .react-calendar__tile:hover {
  cursor: not-allowed;
}

.custom-schedular-modal .active-day-tile::after{
  background: #ffe7eb !important;
  border-radius: 50px !important;
}

.custom-schedular-modal .active-day-tile:hover{
color: black !important;
background: #ffe7eb !important;
}

.custom-schedular-modal .react-calendar__tile--active:enabled.day-tile.react-calendar__tile--now::after, .react-calendar__tile--active:enabled:focus.day-tile.react-calendar__tile--now::after {
  border: none !important;
  background: linear-gradient(142.42deg, #19b0bb 38.71%, #0952DA 140.16%) !important;
} 

 .calendar-containers {
  position: relative;
}

.calendar-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  background: linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%);
  padding: 10px;
  border-radius: 8px;
  backdrop-filter: blur(500px);
}

.calendar-input {
  cursor: pointer;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid rgba(229, 229, 229, 0.5);
  border-radius: 4px;
  background: transparent;
  color: rgba(216, 216, 216, 1);
}
