@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root{
  font-family: 'Poppins';
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

h1 {
  text-align: center;
}

.MuiTypography-root {
  font-family: 'Poppins';
}

.custom-h3 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 50px;
  line-height: 1.167;
  letter-spacing: 0em;
  font-weight: 700;
  color: white;
}

.custom-h4 {
  font-family: 'Poppins';
  font-weight: 350;
  font-size: 40px;
  line-height: 1.167;
  letter-spacing: 0em;
  font-weight: 700;
  color: white;
}

#customBtn {
  background: linear-gradient(180deg, rgba(9, 55, 114, 1) 0%, rgba(15, 81, 167, 1) 100%);
  color: white;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.glass-effect{
  background: rgba(105, 13, 197, 0.103)
}

.button {
  position: relative;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(45deg, #0ce39a, #69007f, #fc0987);
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 15px;
}

.button span {
  position: relative;
  z-index: 1;
}

.button::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: #200627;
  border-radius: 50px;
  transition: 0.5s;
}

.button:hover::before {
  opacity: 0.7;
}

.button::after {
  content: "";
  position: absolute;
  inset: 0px;
  background: linear-gradient(45deg, #0ce39a, #69007f, #fc0987);
  border-radius: 9px;
  transition: 0.5s;
  opacity: 0;
  filter: blur(20px);
}

.button:hover:after {
  opacity: 1;
}

.button svg {
  color:white;
}

/* Global Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(34, 52, 97, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(216, 216, 216, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(216, 216, 216, 0.8);
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(75, 59, 92, 0.5) rgba(34, 52, 97, 0.1);
}

@media (max-width: 1100px) {
  .custom-h3 {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 30px;
    line-height: 1.167;
    letter-spacing: 0em;
    font-weight: 700;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}

#mcq-section,
#coding-section,
#ai-interview-section {
  page-break-after: always;
}

.main-header button {
  padding: 8px 16px;
  box-sizing: border-box;
}

.main-header button:hover {
  color: transparent;
  background: linear-gradient(267.39deg, #FF60B6 0.32%, #2960FD 100.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.table-footer button:hover {
  background: rgb(24, 7, 24);
}

.custom-iconbutton {
  display: flex;
  flex-direction: column;
}

.notification-card {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: #606c88;
  background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);
  background: linear-gradient(to right top, #3f4c6b, #606c88);
  padding: 10px;
  box-shadow: 0px 87px 78px -39px rgba(0, 0, 0, 0.4);
}

.notification-card span {
  height: fit-content;
}

.notification-icon svg {
  height: 1.5rem;
  width: 1.5rem;
  color: white;
}

.notification-card .desc {
  margin-left: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.view-result {
  margin-top: 10px;
  width: fit-content;
  background-color: #284385;
  color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 0.375rem 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  border: 1px solid rgba(253, 253, 253, 0.363);
  margin-top: 5px;
  font-weight: 600;
}

.view-result:hover {
  background-color: #153586;
}

nav {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

nav button {
  white-space: nowrap;
  padding: 8px 16px;
}

.main-header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.search-input {
  flex: 1;
  box-sizing: border-box;
}

.search-input input {
  width: 100%;
}

.notification-popup {
  position: fixed;
  top: 70px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  background: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: 400px;
  z-index: 100000;
  width: 90%;
}

.notification-popup ul {
  list-style-type: none;
}

@media (max-width: 1100px) {
  .notification-popup {
    right: 5px;
    top: 100px;
    width: 100%;
    max-width: 95%;
  }
}

nav {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.notification-popup h4 {
  margin-top: 0;
  font-size: 16px;
  color: #333;
}

.notification-popup ul {
  padding: 0;
}

.notification-popup p {
  margin: 0;
  color: #666;
}

@media (max-width: 750px) {
  .main-header {
    margin-left: 0;
  }

  .main-content {
    margin-left: 0px;
  }

  .suggestions-dropdown {
    top: 100px;
  }
}

.suggestions-dropdown {
  position: absolute;
  top: 60px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.suggestions-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestions-dropdown li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-dropdown li:hover {
  background-color: #f0f0f0;
}

/* Sidebar */
.sidebar {
  width: 100%;
  max-width: 200px;
  background-color: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e0e0e0;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 1000;
  height: 100vh;
}

.sidebar.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
  color: #51459E;
}

.logo {
  font-weight: bold;
}

.sidebar-profile {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.profile-name {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 0px;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  object-fit: cover;
}

.profile-role {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #9e9e9e;
}

.sidebar-nav .css-10vjzfb-MuiButtonBase-root-MuiButton-root,
.sidebar .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #51459E;
  font-family: 'POPPINS';
}

.sidebar-nav button {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.sidebar-logout {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

@media (min-width: 750px) {
  .sidebar {
    transform: translateX(0);
  }
}

/* Dasboard */
.custom-card .custom-card-title {
  font-size: 1.2rem;
  font-family: 'Poppins';
}

.card-grid .css-10zsxzp-MuiTypography-root {
  font-size: 1.5rem;
  font-family: 'Poppins';
  font-weight: bold;
}

.card-grid .custom-card {
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(9, 55, 114, 1) 0%, rgba(15, 81, 167, 1) 100%) !important;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  max-height: 160px;
}

.card-grid .custom-card-content {
  display: flex;
  justify-content: space-evenly;
}

.card-grid .custom-card:hover {
  transform: scale(1.05);
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  border: none;
  font-family: 'Poppins';
  line-height: 1.125em;
  font-size: 1rem;
  padding: 15px 0px;
}

/* Basic button styling */
.react-calendar button {
  color: white;
  font-size: 16px;
  border-radius: 50px !important;
  border: 1.5px solid transparent;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.react-calendar button:focus {
  color: white;
  font-size: 16px;
  border-radius: 50px !important;
  border: 1.5px solid transparent;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: transparent;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

/* Hover state for day tiles */
.react-calendar__month-view__days button:enabled:hover {
  cursor: pointer;
  background-color: #666666;
  color: black;
  -webkit-text-fill-color: black;
}

/* Weekday labels */
.react-calendar__month-view__weekdays__weekday {
  color: #666666;
  font-size: 14px;
}

/* Remove text decoration from abbreviations */
abbr[title] {
  text-decoration: none;
}

/* Navigation label (month/year) */
.react-calendar__navigation__label {
  font-size: 1rem;
  
}

/* Navigation buttons */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none !important;
  color: white;
  -webkit-text-fill-color: white;
}

/* Today's date */
.react-calendar__tile--now {
  background-color: #666666 !important;
  color: black !important;
  -webkit-text-fill-color: black !important;
}

/* Selected date */
.react-calendar__tile--active {
  background-color: #666666 !important;
  color: black !important;
  -webkit-text-fill-color: black !important;
}

/* Range selection styles */
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBetween {
  background-color: #666666 !important;
  color: black !important;
}

/* Hover effect during range selection */
.react-calendar__tile--hover {
  background-color: #666666 !important;
  color: black !important;
  -webkit-text-fill-color: black !important;
}

/* Override any gradient backgrounds */
.react-calendar button:not(.react-calendar__navigation button):enabled:hover,
.react-calendar button:not(.react-calendar__navigation button):enabled:focus {
  background: #666666;
  -webkit-background-clip: unset;
  background-clip: unset;
  color: black;
  -webkit-text-fill-color: black;
}

@media (max-width: 768px) {

  .interview-item {
    margin-left: 20px;
    flex-direction: column;
    align-items: flex-end;
  }
}

.search-results {
  background: "linear-gradient(233.57deg, rgba(34, 52, 97, 0.25) -5.01%, rgba(11, 10, 31, 0.05) 100%)";
  opacity: 1;
  backdrop-filter: blur(500px);
  position: absolute;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  margin-top: 5px;
  color: rgba(216, 216, 216, 1);
}

.search-results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 8px 12px;
  cursor: pointer;
}

.no-results {
  padding: 8px 12px;
  color: #888;
}

.formControlGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.formControlGroup h4 {
  margin: 0 0 5px;
  font-size: 1rem;
  font-weight: bold;
}

.formControlGroup .MuiFormControlLabel-root {
  margin-bottom: 5px;
}


.report-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-reports {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.job-vacancies {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.candidate-reports h2,
.job-vacancies h2 {
  text-align: center;
  margin: 0%;
}

.search-option {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 25px;
}

.filters-button button {
  padding: 10px;
  border: solid 1px #153586;
}

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 1rem;
}

.table-container table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;

}

.table-container input {
  padding: 10px 20px;
  font: inherit;
  color: rgba(216, 216, 216, 1);
}

.table-container .custom-th {
  padding: 0.75rem 1rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(216, 216, 216, 1);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  align-items:first baseline;
}

.table-container .custom-th-left{
  padding-left: 1rem;
  padding-right: 0%;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(216, 216, 216, 1);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.table-container .custom-td-left{
  padding-left: 1rem;
  
  text-align: left;
}
.table-container th {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(216, 216, 216, 1);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  width: auto;
}

.table-container tbody {
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 10px;
}

.table-container tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  align-items: center;
}

.table-container td {
  font-family: 'Poppins';
  text-align: center;
  padding: 12px 16px;
  font-size: 1rem;
  color: rgba(216, 216, 216, 1);
  white-space: nowrap;
  width: auto;
}

.table-container td:first-child {
  font-weight: 400;
}

.table-container .avatar-cell,
.interview-item .avatar-cell {
  display: flex;
  align-items: center;
}

.table-container .avatar-cell .MuiAvatar-root,
.interview-item .avatar-cell .MuiAvatar-root {
  margin-right: 1rem;
  width: 35px;
  height: 35px;
  font-size: 1rem;
}

.table-container .avatar-cell div {
  font-size: 1rem;
  font-weight: 500;
  color: #1f2937;
}

.table-container .MuiBadge-root {
  font-size: 0.75rem;
}

.table-footer {
  border-radius: 10px;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  border-radius: 10px;
}

.table-footer .pagination-text {
  font-size: 0.875rem;
  color: rgba(216, 216, 216, 1);
}

.table-footer .pagination-text .font-medium {
  font-weight: 500;
}

.table-footer .MuiPagination-root {
  display: flex;
  align-items: center;
}

.table-footer .MuiPagination-root .MuiButtonBase-root {
  color: rgba(216, 216, 216, 1);
}

.table-footer .MuiPaginationItem-root.Mui-selected .MuiButtonBase-root {
  color: white;
  background-color: #1976d2;
}

.avatar-cell {
  display: flex;
  align-items: center;
}

.avatar-cell div {
  margin-left: 8px;
}

@media (max-width: 600px) {

  .responsive-table th,
  .responsive-table td {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .maincontent {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .maincontent {
    font-size: 0.7rem;
  }
}

@media (max-width: 500px) {
  .maincontent {
    font-size: 0.6rem;
  }

  .search-option {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.status-completed {
  width: 110px;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  gap: 4px;
  background: rgba(109, 201, 158, 0.3);
  color: #069855;
  border: 0.5px solid rgba(13, 255, 144, 1);
  border-radius: 8px;
}

.status-in-progress {
  width: 110px;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  gap: 4px;
  background: rgba(211, 156, 29, 0.3);
  color: #D39C1D;
  border: 0.5px solid rgba(211, 156, 29, 1);
  border-radius: 8px;
}

.status-pending {
  text-align: center;
  width: 110px;
  box-sizing: border-box;
  padding: 5px;
  gap: 4px;
  background: rgba(214, 37, 37, 0.3);
  color: #D62525;
  border: 0.5px solid rgba(214, 37, 37, 1);
  border-radius: 8px;
}

.status-icon {
  margin-right: 5px;
}

.filters-container {
  position: relative;
  width: 250px;
  padding: 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filters-container .close-button:hover {
  background-color: #f44336;
}

.filters-container .filters-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters {
  margin: 30px;
  padding: 25px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.score-range,
.date-range {
  display: flex;
  gap: 20px;
}

.filter-group label {
  font-weight: 600;
  font-size: 1rem;
  color: #2d3748;
}

.filter-group input,
.filter-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-group input:focus,
.filter-group select:focus {
  border-color: #3182ce;
  box-shadow: 0 0 5px rgba(49, 130, 206, 0.3);
  outline: none;
}

.date-range input {
  flex: 1;
}

@media screen and (max-width: 768px) {

  .app-table th,
  .app-table td {
    padding: 10px;
  }

  .app-header {
    font-size: 1.75rem;
  }

  .app-section h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {

  .app-table th,
  .app-table td {
    padding: 8px;
    font-size: 0.9rem;
  }

  .app-button {
    padding: 8px 14px;
    font-size: 0.9rem;
  }

  .app-header {
    font-size: 1.5rem;
  }

  .app-section h2 {
    font-size: 1.2rem;
  }
}

.filter-summary ul {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.filter-summary li {
  padding: 10px;
}

.tooltip-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-icon .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.progress-bar {
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #f0f0f0;
  margin: 4px 0;
}

.progress-bar div {
  height: 100%;
}

.progress-bar span {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
}

.audio-button path {
  color: black;
}

.actions-container {
  position: relative;
}

.actions-button {
  color: white;
  padding: 10px;
}

.actions-dropdown {
  position: absolute;
  background: rgba(105, 13, 197, 0.103);
  backdrop-filter: blur(50px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 100%;
  left: 0;
  border-radius: 5px;
  display: none;
  flex-direction: column;
  padding: 5px;
}

.profile-dropdown {
  position: absolute;
  background-color: white;
  top: 100%;
  right: 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  z-index: 1000;
  padding: 5px;
}

.actions-container:hover .actions-dropdown {
  display: flex;
  align-items: flex-start;
}

.actions-container:hover .profile-dropdown {
  display: flex;
  align-items: flex-start;
}

.dropdown-item {
  color: white;
  background-color: transparent;
  padding: 10px;
  text-align: left;
  border: none;
  width: 100%;
  white-space: nowrap;
}

.dropdown-item .mcq-dropdown {
  display: flex;
  align-items: center;
  margin: 0;
  height: 36px;
}

.mcq-dropdown {
  position: relative;
}

.mcq-submenu {
  position: absolute;
  background: rgba(105, 13, 197, 0.103);
  backdrop-filter: blur(50px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 0;
  left: 102%;
  border-radius: 5px;
  display: none;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
}

.mcq-dropdown:hover .mcq-submenu {
  display: flex;
}

.submenu-item {
  color: white;
  padding: 5px;
}

.container {
  padding: 20px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  gap: 20px;
  width: 100%;
  max-width: 1500px;
  height: auto;
  flex-wrap: wrap;
}

@media (max-width: 652px) {
  .container {
    padding: 10px;
    gap: 10px;
  }
}

.dynamic-left-section {
  display: flex;
  gap: 20px;
  padding: 20px;
  align-items: top;
}

.response-received .dynamic-left-section {
  flex-direction: column;
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  margin-top: 43px;
  margin-bottom: 43px;
  max-width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out;
}

.right-section {
  display: none;
}

.select-job-role {
  margin-top: 20px;
  width: 100%;
}

.jd-viewbox {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.notify input {
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  border: solid 1px gray;
}

.calendar-container {
  margin: 20px;
}

.time-frame-selector-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.response-report .checkbox-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
}

.response-received .container {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  height: auto;
}

.response-received .left-section {
  width: 40%;
  height: fit-content;
}

.response-received .right-section {
  display: block;
  width: 60%;
  padding-left: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  height: fit-content;
}